/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you require will output into a single css file (app.scss in this case)
require('../css/app.scss');

// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');

import CookieBox from 'cookie-consent-box';
new CookieBox({ backgroundColor: '#079', url: '/politique-de-confidentialite', language: 'fr', target: '_self' }).init();